<template>
    <div>
      <div ref="navBar">
        <UserAdministrationNavBar :isuploadNormals="true" @on-back="handleBack" />
      </div>
  
      <b-badge class="w-100" variant="secondary" show size="sm">Upload Normals</b-badge>
  
      <b-container class="mt-2 white-font" fluid>
        <b-row class="mx-2">
          <b-col cols="2">
            <b-row align-h="between" class="my-2">
              <!--<b-col class="mt-1">Username* </b-col>-->
              <input type="file" ref="fileUpload" v-on:change="onFileUpload" accept=".doc,.docx," style="position:fixed; left:-100em" multiple/>
              <b-button class="mt-3" @click="importTemplates()" :loading="isLoading" title="Select Normal(s)" :disabled="unavailableCustomerIds().length==filteredCustomers.length">
                    <span>Select Normal(s)</span>
              </b-button>
          </b-row>
          </b-col>
          
          <b-col cols="5">
            
            
              <b-table class="white-font" :fields="fields" :items="items" :small="true" >
                
                <template #cell(customer)="data" v-if="isSuperAdmin">
                  <b-dropdown class="dropdown-user-administration-dropdown" :text="selectedCustomers[data.item.id]
                      ? selectedCustomers[data.item.id] + ' '
                      : 'Select customer'
                    ">
                    <b-dropdown-item href="#" v-for="customer in filteredCustomers" :key="customer.id" :disabled="(data.item.customerId==customer.id) || unavailableCustomerIds().includes(customer.id)"
                      @click="onCustomerSelect(data.item, customer)">{{ customer.name }}</b-dropdown-item>
                  </b-dropdown>
                </template>
            
                <template #cell(tenants)="data">
                  <b-dropdown id="dropdown-form" ref="dropdown" class="dropdown-user-administration-dropdown" :text="selectedTenants[data.item.id] && (selectedTenants[data.item.id].length > 0) 
                      ? selectedTenants[data.item.id].length +
                      ' Tenant(s) Selected'
                      : 'Select Tenant(s)'  
                    "
                    :disabled="selectedCustomers[data.item.id]==null">
                    <b-dropdown-form class="w-100">
                      <b-form-checkbox-group v-model="selectedTenants[data.item.id]" stacked @change="onTenantSelect()">
                        <div v-for="(tenant, index) in tenantsOfCustomers[data.item.id]" :key="index">
                          <b-form-checkbox :value="tenant.value" :disabled="unavailableTenantIds(data.item.id).includes(tenant.value)">
                            {{ tenant.text }}
                          </b-form-checkbox>
                        </div>
                      </b-form-checkbox-group>
                    </b-dropdown-form>
                  </b-dropdown>
                </template>
                           
              </b-table>
                
            
          </b-col>
          <b-col cols="2">
            <b-row align-h="between" class="my-2">
              <template>
                <b-dropdown v-if="isSuperAdmin" id="userId" ref="dropdown" class="mt-2" cols="auto" :text="selectedUser && selectedUser.length > 0
                    ? selectedUser.length +
                    ' User(s) Selected'
                    : 'Select User(s)'
                  " 
                  :disabled="!isTenantAdded()">
                  <b-dropdown-form class="w-100">
                    <b-form-checkbox-group v-model="selectedUser" stacked @change="onTenantSelect()">
                      <div v-for="(user, index) in this.users" :key="index">
                        <b-form-checkbox :value="user">
                          {{ user }}
                        </b-form-checkbox>
                      </div>
                    </b-form-checkbox-group>
                  </b-dropdown-form>
                </b-dropdown>
              </template>  
          </b-row>
          </b-col>
          
          <b-col cols="1">
            <b-row align-h="bottom" class="my-2">
              <b-checkbox id="ownId" v-model="uploadNormal.own" title="Own Normal"> 
                Own Normal
              </b-checkbox>
          </b-row>
          </b-col>

          <b-col cols="2">
            <b-row align-h="between" class="my-2">
                    
                    <b-button class="mt-2" @click="uploadNormals()" :loading="isLoading" title="Upload Normal(s)" :disabled="unavailableCustomerIds().length==filteredCustomers.length">
                    <span>Upload Normal(s)</span>
                </b-button>
          </b-row>
          </b-col>

          
        </b-row>
      </b-container>
      <UserWorklistSetting :userWorklistSettingsDialog="userWorklistSettingsDialog" @close="closeWorklistSettingModal"
        :userInfo="userInfo" :userData="userWorklistSettingData" :selected-customer-id="selectedCustomerId"
        :userSignature="userWorklistSignature"
        @save-setting="saveUserSetting" @save-signature="saveSignature" />
      <b-modal title="Alert" :visible="isUnsavedChanges" header-bg-variant="secondary" header-text-variant="light"
        body-bg-variant="dark" body-text-variant="light" footer-bg-variant="dark" footer-text-variant="light"
        @hidden="isUnsavedChanges = false" content-class="shadow" ok-title="Yes" @ok="goBack">You have unsaved changes. Are
        you
        sure you want to go back?</b-modal>
    </div>
  </template>
  
  <script>
  import permissions from "../common/permissions";
  import webServices from '../common/webServices'
  import userAdministrationWebServices from "../common/userAdministrationWebServices";
  import UserAdministrationNavBar from "./UserAdministrationNavBar.vue";
  import UserWorklistSetting from "./UserWorklistSetting.vue";
  
  export default {
    components: {
      UserAdministrationNavBar,
      UserWorklistSetting,
    },
    props: {
      fetchedUsers: Array,
    },
    data() {
      return {
        uploadNormal: {
          "active": false,
       },
        users: [],
        selectedUser:[],
        isUnsavedChanges: false,
        permissionGroupSelection: { 0: [] },
        allSelected: {},
        allGroupedPermissions: [],
        userWorklistSettingsDialog: false,
        selectedCustomers: {
          0: null
        },
        searchedPermission: "",
        searchedTenant: "",
        selectedTenants: {},
  
        errors: {},
  
        isLoading: false,
  
        items: [],
        userInfo: {
          username: "",
          email: "",
          firstName: "",
          lastName: "",
          mobileNumber: "",
        },
        selectedPermissions: {
          0: {}
        },
        tenantOptions: {},
        permissionsOptions: [],
        tenantsOfCustomers: {},
        selectedCustomerId: "",
        selectedRowId: null,
        permissionCounts: { 
          0: 0
        },
        customerLevelPermisisons: [],
        templateFiles: [],
        templateName: '',
        userWorklistSettingData: null,
        userWorklistSignature: null, // image blob
        isFormDirty: false,
        initialFormData: {
          username: "",
          email: "",
          firstName: "",
          lastName: "",
          mobileNumber: ""
        }
      };
    },
    async mounted() {
      await this.loadTenantsAndPermissions();
      this.addRow();
      /*
      this.users = [];
      const responseUser = await userAdministrationWebServices.getUserIds('CUSTOMER1', this.userId);
      const userIds = responseUser;
      for (let i = 0; i < userIds.length; i++) {
        const response = await userAdministrationWebServices.fetchUserById(userIds[i])
        this.users.push(response.username)
      }
      
      this.selectedUser = [];
      var usersArr = args[6].toString().split(',')
      for (let i = 0; i < usersArr.length; i++) {
        this.selectedUser.push(usersArr[i])
      } */
    },
    computed: {
      fields() {
        if (this.isSuperAdmin) {
          return [
            //{ key: "actions", label: "Actions" },
            { key: "customer", label: "" },
            { key: "tenants", label: "" },
            //{ key: "user", label: "Users" },
            //{ key: "permissions", label: "Permissions" },
            //{ key: "WorklistSetting", label: "Worklist Settings" },
          ];
        } else {
          return [
            { key: "customer", label: "" },
            { key: "tenants", label: "" },
            //{ key: "user", label: "Users" },
            //{ key: "actions", label: "Actions" },
            //{ key: "tenants", label: "Tenants" },
            //{ key: "permissions", label: "Permissions" },
            //{ key: "WorklistSetting", label: "Worklist Settings" },
  
          ];
        }
      },
      filteredCustomers() {
        if (this.tenantOptions.length > 0) {
          return this.tenantOptions.filter((item) => Object.keys(item.tenants).length > 0);
        } else {
          return [];
        }
      },
      filteredTenants() {
        return this.tenantOptions;
      },
      isSuperAdmin() {
        return permissions.isSuperAdmin();
      },
      checkUserInfoFilled() {
        for (const key in this.userInfo) {
          if (!this.userInfo[key]) {
            return false;
          }
          if (key === 'email') {
            if (!this.validateEmail(this.userInfo[key])) {
              return false;
            }
          }
        }
        return true;
      },
      isDirty() {
        for (const key in this.userInfo) {
          if (this.userInfo[key] !== this.initialFormData[key] || this.isFormDirty) {
            return true;
          }
        }
        return false;
      }
    },
    methods: {
      goBack() {
        this.$router.push('/user_administration')
      },
      handleBack() {
        if (this.isDirty) {
          this.isUnsavedChanges = true
        } else {
          this.$router.push('/user_administration')
        }
      },
      isTenantAdded() {
        var tenantsAdded = (this.items.length > 0);
        this.items.forEach(item => {
          tenantsAdded &= (this.selectedCustomers[item.id] && this.selectedTenants[item.id] && (this.selectedTenants[item.id].length > 0))
        })
        //alert(tenantsAdded)
        return tenantsAdded;
      },
      isPermissionDisabled(id, permission) {
        var disabled = permission.requires && 
          this.selectedPermissions[id] && 
          this.selectedPermissions[id][permission.requires.group_name] && 
          !this.selectedPermissions[id][permission.requires.group_name].includes(permission.requires.permission_value)
        return disabled
      },
      onPermissionAllSelection(checked, id) {
        this.$log.debug(`checked=${checked} id=[${id}]`)
        this.permissionGroupSelection[id] = []
        let selectedPermissions = {}
        this.allGroupedPermissions.forEach(group => {
          selectedPermissions[group.name] = []
        })
        if (checked) {
          this.allGroupedPermissions.forEach(group => {
            this.permissionGroupSelection[id].push(group.name)
            group.permissions.forEach(permission => {
              selectedPermissions[group.name].push(permission.value)
            })
          })
        }
        this.selectedPermissions[id] = selectedPermissions
        this.isFormDirty = true
        this.syncCustomerPermissions(id)
        this.calcPermissionCounts()
        this.$forceUpdate()
      },
      onPermissionGroupSelection(e, id, group) {
        if (e.includes(group.name)) {
          const selectedPermissions = []
          group.permissions.forEach((item) => {
            let select = true
            if (item.requires && item.requires.group_name !== group.name) {
              select = this.selectedPermissions[id][item.requires.group_name].includes(item.requires.permission_value);
            }
            if (select) {
              selectedPermissions.push(item.value)
            }
          })
          this.$set(this.selectedPermissions[id], group.name, selectedPermissions)
        } else {
          this.$set(this.selectedPermissions[id], group.name, [])
        }
        this.isFormDirty = true
        this.syncCustomerPermissions(id)
        this.calcPermissionCounts()
        this.$forceUpdate()
      },
      onPermissionSelection(e, id, group) {
        // SP-651 - make sure "edit" permissions not checked if "view" was unchecked.
        for (var i=0; i < group.permissions.length; i++) {
          const permission = group.permissions[i].value;
          if (!e.includes(permission)) {
            // Permission is being removed...remove any dependent permissions...
            //
            this.allGroupedPermissions.forEach(groupToCheck => {
              groupToCheck.permissions.forEach(permissionToCheck => {
                if (permissionToCheck.requires) {
                  if (permissionToCheck.requires.permission_value == permission) {
                    if (permissionToCheck.requires.group_name == groupToCheck.name) {
                      const index = e.indexOf(permissionToCheck.value);
                      if (index > -1) {
                        e.splice(index, 1);
                        // group unselection will be handled below
                      }
                    }
                    else {
                      let index = this.selectedPermissions[id][groupToCheck.name].indexOf(permissionToCheck.value);
                      if (index > -1) {
                        this.selectedPermissions[id][groupToCheck.name].splice(index, 1);
                        index = this.permissionGroupSelection[id].indexOf(groupToCheck.name);
                        if (index > -1) {
                          this.permissionGroupSelection[id].splice(index, 1);
                        }
                      }
                    }
                  }
                }
              })
            })
          }
        }
  
        // Handle toggling group checkbox if needed.
        //
        if (e.length != group.permissions.length) {
          if (this.permissionGroupSelection[id] && this.permissionGroupSelection[id].includes(group.name)) {
            const index = this.permissionGroupSelection[id].indexOf(group.name);
            if (index > -1) {
              this.permissionGroupSelection[id].splice(index, 1);
            }
          }
        }
        else if (!this.permissionGroupSelection[id].includes(group.name)) {
          this.permissionGroupSelection[id].push(group.name)
        }
        this.isFormDirty = true
        this.syncCustomerPermissions(id)
        this.calcPermissionCounts()
        this.$forceUpdate()
      },
      saveUserSetting(setting) {
        const matchItem = this.items.find((item) => item.id == this.selectedRowId)
        if (matchItem) {
          this.items.forEach(item => {
            if (item.customerId == matchItem.customerId) {
              item.worklistSettings = setting
            }
          })
          this.isFormDirty = true;
        }
        else {
          this.$log.error(`No item found with id=${this.selectedRowId}`)
        }
      },
      saveSignature(signature) {
        const matchItem = this.items.find((item) => item.id == this.selectedRowId)
        if (matchItem) {
          this.items.forEach(item => {
            if (item.customerId == matchItem.customerId) {
              item.signature = signature
            }
          })
          this.isFormDirty = true;
        }
        else {
          this.$log.error(`No item found with id=${this.selectedRowId}`)
        }
      },
      async loadTenantsAndPermissions() {
        try {
          const response =
            await userAdministrationWebServices.fetchAdminTenants();
  
          this.tenantOptions = response;
          if (!this.isSuperAdmin) {
            const filteredTenants = this.tenantOptions.find((item) => item.id == this.$store.state.customerId)
            this.tenantsOfCustomers[0] = filteredTenants.tenants;
            this.selectedCustomers[0] = filteredTenants.name;
          }
        } catch (error) {
          this.$log.error("error::", error);
        }
        const groupedPermissions = permissions.getAvailableGroupedTenantPermissions()
        this.allGroupedPermissions = groupedPermissions
        this.allGroupedPermissions.forEach(group => {
          this.$log.debug(`Adding group=[${group.name}] to selectedPermissions[0]`)
          this.selectedPermissions[0][group.name] = []
        });
      },
      formatString(str) {
        return str.replace(/_/g, ' ').replace(/(\w)\w*/, (match, letter) => letter.toUpperCase() + match.slice(1));
      },
      closeWorklistSettingModal() {
        this.userWorklistSettingData = {}
        this.userWorklistSettingsDialog = false;
      },
      userWorklistSetting(item) {
        this.selectedRowId = item.id;
        const customerName = this.selectedCustomers[item.id];
        const selectedCustomer = this.tenantOptions.find(item => item.name == customerName)
        this.selectedCustomerId = (selectedCustomer) ? selectedCustomer.id : "__NoT_FoUnD__";
        try {
          if (item.signature) {
            this.userWorklistSignature = item.signature
          } else {
            this.userWorklistSignature = null;
          }
          if (item.worklistSettings) {
            this.userWorklistSettingData = item.worklistSettings;
          } else {
            this.userWorklistSettingData = userAdministrationWebServices.DEFAULT_WORKLIST_SETTINGS;
          }
          this.populateCustomerDataForUser()
          this.userWorklistSettingsDialog = true
        } catch (error) {
          this.$log.error("Error while getting worklist settings", error)
        }
      },
      unavailableCustomerIds() {
        var customerIds = []
        try {
          var tenantIdsForCustomer = {}
          Object.keys(this.selectedTenants).forEach(id => {
            const matchItem = this.items.find((item) => item.id == id)
            if (matchItem !== undefined) {
              const customerId = matchItem.customerId
              if (tenantIdsForCustomer[customerId] === undefined) {
                tenantIdsForCustomer[customerId] = []
              }
              tenantIdsForCustomer[customerId].push(...this.selectedTenants[id])
  
            }
          })
          Object.keys(tenantIdsForCustomer).forEach(customerId => {
            var customer = this.tenantOptions.find((item) => item.id == customerId)
            if (customer && customer.tenants && (tenantIdsForCustomer[customerId].length == customer.tenants.length)) {
              customerIds.push(customerId)
            }
          })
        }
        catch (err) {
          this.$log.warn(`unable to initialize unavailable customer ID list: ${err.message}`)
        }
        return customerIds
      },
      unavailableTenantIds(checkIndex) {
        var tenantIds = []
        Object.keys(this.selectedTenants).forEach(index => {
          if (index != checkIndex) {
            tenantIds.push(...this.selectedTenants[index])
          }
        })
        return tenantIds
      },
      showWorklistSettings(checkIndex) {
        // Only want to show worklist settings button in first row for a customer.
        //
        var show = false
        try {
          const checkItem = this.items.find((item) => item.id == checkIndex)
          for (var i = 0; i < this.items.length; i++) {
            if (this.items[i].customerId == checkItem.customerId) {
              show = (this.items[i].id == checkIndex)
              break;
            }
          }
        }
        catch(err) {
          this.$log.error(`Unable to get showWorklistSettings: ${err.message}`)
          show = true
        }
        return show
      },
      syncCustomerPermissions(checkIndex) {
          const checkItem = this.items.find((item) => item.id == checkIndex)
          if (checkItem && (this.selectedPermissions[checkIndex] != null)) {
            const selectedPermissionsIndexes = Object.keys(this.selectedPermissions).sort()
            for(var i = 0; i < selectedPermissionsIndexes.length; i++) {
              const index = selectedPermissionsIndexes[i]
              const item = this.items.find((item) => item.id == index)
              if ((index != checkIndex) && item && (item.customerId == checkItem.customerId)) {
                this.allGroupedPermissions.forEach(group => {
                  group.permissions.forEach(permission => {
                    if (permission.level == 'customer') {
                      let selectedPermissions = [...this.selectedPermissions[index][group.name]]
                      if (this.selectedPermissions[checkIndex][group.name].includes(permission.value)) {
                        if (!selectedPermissions.includes(permission.value)) {
                          selectedPermissions.push(permission.value)
                          this.$set(this.selectedPermissions[index], group.name, selectedPermissions)
                        }
                      }
                      else if (selectedPermissions.includes(permission.value)) {
                        const pI = selectedPermissions.indexOf(permission.value);
                        if (pI > -1) {
                          selectedPermissions.splice(pI, 1);
                          this.$set(this.selectedPermissions[index], group.name, selectedPermissions)
                        }
                      }
                    }
                  })
                
                  if (this.selectedPermissions[index][group.name].length != group.permissions.length) {
                    if (this.permissionGroupSelection[index] && this.permissionGroupSelection[index].includes(group.name)) {
                      const gI = this.permissionGroupSelection[index].indexOf(group.name);
                      if (gI > -1) {
                        this.permissionGroupSelection[index].splice(gI, 1);
                      }
                    }
                  }
                  else if (!this.permissionGroupSelection[index].includes(group.name)) {
                    this.permissionGroupSelection[index].push(group.name)
                  }
                })
              }
            }
          }
      },
      async onTenantSelect() {
        this.isFormDirty = true
        this.$forceUpdate()
        this.users = [];
        const responseUser = await userAdministrationWebServices.getUserIds('CUSTOMER1', this.userId);
        const userIds = responseUser;
        
        for (let i = 0; i < userIds.length; i++) {
          const response = await userAdministrationWebServices.fetchUserById(userIds[i])
          this.users.push(response.username)
        }
      },
      onCustomerSelect(item, customer) {
        if (item.customerId != customer.id) {
          //alert(customer.name)
          item.customerId = customer.id;
          this.$set(this.selectedCustomers, item.id, customer.name);
          this.tenantsOfCustomers[item.id] = customer.tenants;
          this.selectedTenants[item.id] = []
          this.permissionGroupSelection[item.id] = []
          this.selectedPermissions[item.id] = {}
          this.allGroupedPermissions.forEach(group => {
            this.selectedPermissions[item.id][group.name] = []
          })
          if (!this.showWorklistSettings(item.id)) {
            for (let i = 0; i < this.items.length; i++) {
              if (this.items[i].customerId == item.customerId) {
                item.worklistSettings = this.items[i].worklistSettings
                item.signature = this.items[i].signature
                break;
              }
            }
          }
          this.isFormDirty = true
  
          for(let i = 0; i < this.items.length; i++) {
            if ((this.items[i].id != item.id) && (this.items[i].customerId == item.customerId)) {
              this.syncCustomerPermissions(this.items[i].id)
              break
            }
          }
          this.calcPermissionCounts();
          this.$forceUpdate()
        }
      },
      validateEmail(email) {
        const emailRegex =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRegex.test(email);
      },
      validateInput(field) {
        if (field === "email") {
          if (!this.validateEmail(this.userInfo.email)) {
            // SP-608
            this.errors.email = "Please enter a valid email address.";
          } else {
            delete this.errors.email;
          }
        } else {
          delete this.errors[field];
        }
      },
      validateUserInputField() {
        this.errors = {};
        let isValid = true;
        if (this.userInfo.username == "") {
          // SP-608
          this.errors.username = "Username is required";
          isValid = false;
        }
        // SP-608 
        if (this.userInfo.username.length > 32) {
          this.errors.username = "Username should be 32 characters or less";
          isValid = false;
        }
        if (this.userInfo.email == "") {
          this.errors.email = "Email is required";
          isValid = false;
        } else if (!this.validateEmail(this.userInfo.email)) {
          // SP-608
          this.errors.email = "Please enter a valid email address.";
          isValid = false;
        }
        // SP-608 
        if (this.userInfo.email.length > 128) {
          this.errors.email = "Email address should be 128 characters or less";
          isValid = false;
        }
        if (this.userInfo.firstName == "") {
          this.errors.firstName = "First name is required";
          isValid = false;
        }
        // SP-608 
        if (this.userInfo.firstName.length > 128) {
          this.errors.firstName = "First name should be 128 characters or less";
          isValid = false;
        }
        if (this.userInfo.lastName == "") {
          this.errors.lastName = "Last name is required";
          isValid = false;
        }
        // SP-608 
        if (this.userInfo.lastName.length > 128) {
          this.errors.lastName = "Last name should be 128 characters or less";
          isValid = false;
        }
        if (this.userInfo.mobileNumber == "") {
          this.errors.mobileNumber = " Mobile number is required";
          isValid = false;
        }
        // SP-608 
        if (this.userInfo.mobileNumber.length != 10) {
          this.errors.mobileNumber = "Mobile number should be 10 digits";
          isValid = false;
        }
        return isValid;
      },
      // SP-608
      validateField(e) {
        let char = String.fromCharCode(e.keyCode);
        e = (e) ? e : window.Event;
        if (/^\p{Letter}+$/u.test(char) || /^[\d]+$/.test(char) || /^[-_]+$/.test(char)) {
          return true;
        }
        e.preventDefault();
        return false;
      },
      isLetter(e) {
        let char = String.fromCharCode(e.keyCode);
        if (/^\p{Letter}+$/u.test(char) || /^[ \-']+$/.test(char)) {
          return true;
        }
        e.preventDefault();
        return false;
      },
      isNumber(e) {
        let char = String.fromCharCode(e.keyCode);
        e = (e) ? e : window.Event;
        if (/^[\d]+$/.test(char)) {
          return true;
        }
        e.preventDefault();
        return false;
      },
      calcPermissionCounts() {
        let permissionCounts = {};
        for (const id in this.selectedPermissions) {
          permissionCounts[id] = 0;
          for (const groupName in this.selectedPermissions[id]) {
            permissionCounts[id] += this.selectedPermissions[id][groupName].length;
          }
        }
        this.permissionCounts = permissionCounts
      },
      onReset(itemId) {
        this.$log.debug(`Resetting itemId=${itemId}`)
        if (this.isSuperAdmin) {
          this.selectedCustomers[itemId] = null;
          this.tenantsOfCustomers[itemId] = [];
        }
        this.permissionGroupSelection[itemId] = []
        this.selectedTenants[itemId] = [];
        this.selectedPermissions[itemId] = {};
        this.allGroupedPermissions.forEach(group => {
          this.selectedPermissions[itemId][group.name] = []
        })
        this.permissionCounts[itemId] = 0;
        const item = this.items.find((item) => item.id == itemId)
        if (item) {
          // Only reset if customer is reset (super admin flow) or only row (customer admin flow)
          //
          if (this.isSuperAdmin) {
            item.worklistSettings = userAdministrationWebServices.DEFAULT_WORKLIST_SETTINGS;
            item.signature = null;
          }
          else if (this.showWorklistSettings(itemId)) {
            for (let i=0; i < this.items.length; i++) {
              this.items[i].worklistSettings = userAdministrationWebServices.DEFAULT_WORKLIST_SETTINGS;
              this.items[i].signature = null;
            }
          }
        }
        this.$forceUpdate()
      },
      async uploadNormals() {
        
        if (this.templateFiles.length > 0)  {
          let tenantArray = [];
          this.items.forEach(item => {
            let tenantList = String(this.selectedTenants[item.id])
            tenantArray = tenantList.split(",")
          })
          for (let j = 0; j < tenantArray.length; j++) {
            for (let i = 0; i < this.templateFiles.length; i++) {
                document.body.style.cursor = 'wait'
                let file = this.templateFiles[i];
                var fpath = file.name.replace(/\\/g, '/');
                var fileName = fpath.substring(fpath.lastIndexOf('/')+1, fpath.lastIndexOf('.'));
                this.templateName = fileName
                webServices.createReportTemplateForGroup(fileName, `/${tenantArray[j]}`, this.templateName, file.name)
                await webServices.sleep(500);
                document.body.style.cursor = 'default'
            }
          }
          this.$bvToast.toast(
            "Report template(s) are uploaded Successfully.",
            {
              autoHideDelay: 5000,
              solid: true,
              title: "Information",
              variant: "info",
            }
          );
          document.body.style.cursor = 'default'
        } 
        else {
          this.$bvToast.toast(
            "Report template(s) not selected.",
            {
              autoHideDelay: 5000,
              solid: true,
              title: "Information",
              variant: "info",
            }
          );
        }
      },
      importTemplates() {
        this.$refs.fileUpload.click();
      },
      onFileUpload(e)  {
        if (e.target.files) {
          this.templateFiles = []
          for (let i = 0; i < e.target.files.length; i++) {
              let file = e.target.files[i]
              this.templateFiles.push(file)
          }
        } 
      },
      addRow() {
        const newId = Date.now()
        this.selectedCustomers[newId] = null;
        this.selectedTenants[newId] = [];
        this.permissionGroupSelection[newId] = []
        this.selectedPermissions[newId] = {};
        this.allGroupedPermissions.forEach(group => {
          this.selectedPermissions[newId][group.name] = []
        })
        this.tenantsOfCustomers[newId] = []
        this.permissionCounts[newId] = 0;
        var customerId = ''
        var worklistSettings = userAdministrationWebServices.DEFAULT_WORKLIST_SETTINGS
        var signature = null
        if (!this.isSuperAdmin) {
          // If not super admin, need to auto-select current customer.
          //
          const customer = this.tenantOptions.find((item) => item.id == this.$store.state.customerId);
          customerId = customer.id
          this.selectedCustomers[newId] = customer.name
          this.tenantsOfCustomers[newId] = customer.tenants
          if (this.items.length > 0) {
            worklistSettings = this.items[0].worklistSettings
            signature = this.items[0].signature
          }
        }
        this.items.push({
          id: newId,
          customerId: customerId,
          permissionsHash: Date.now(), // psuedo unique hash
          tenants: {},
          worklistSettings: worklistSettings,
          signature: signature,
        });
        this.isFormDirty = true;
        this.$forceUpdate()
      },
      deleteRow(currRowId) {
        this.$log.debug(`Deleting row for currRowId=${currRowId}`)
        delete this.selectedCustomers[currRowId];
        delete this.permissionGroupSelection[currRowId];
        delete this.selectedPermissions[currRowId];
        delete this.selectedTenants[currRowId];
        delete this.tenantsOfCustomers[currRowId];
        delete this.permissionCounts[currRowId];
        this.items = this.items.filter((eachRow) => eachRow.id !== currRowId);
        this.isFormDirty = true;
        this.$forceUpdate()
      },
      populateCustomerDataForUser() {
        const customers = {};
        this.items.forEach((element) => {
          if (
            this.selectedTenants[element.id] &&
            this.selectedTenants[element.id].length > 0
          ) {
            let selectedPermissions = [];
            for (const permissionSet in this.selectedPermissions[element.id]) {
              selectedPermissions.push(...this.selectedPermissions[element.id][permissionSet]);
            }
  
            // Initialize arrays for Customer and Tenant permissions
            let customerPermissions = [];
            let tenantPermissions = [];
            // Loop through selected permissions and categorize them
            selectedPermissions.forEach(permission => {
              // Check if the permission is in the "customer" level in the original array
              let found = false;
              for (const group of this.allGroupedPermissions) {
                for (const perm of group.permissions) {
                  if (perm.value === permission && perm.level === "customer") {
                    customerPermissions.push(permission);
                    found = true;
                    break;
                  }
                }
                if (found) {
                  break;
                }
              }
              // If not in "customer" level, add to other permissions array
              if (!found) {
                tenantPermissions.push(permission);
              }
            });
            let tenantsForThisCustomer = this.selectedTenants[element.id].reduce(
              (acc, tenantId) => {
                acc[tenantId] = { permissions: tenantPermissions };
                return acc;
              },
              {}
            );
  
            let customer = null
            if (!this.isSuperAdmin) {
              const existingCustomersIds = Object.keys(customers)
              if (existingCustomersIds.includes(this.$store.state.customerId)) {
                tenantsForThisCustomer = { ...customers[this.$store.state.customerId].tenants, ...tenantsForThisCustomer }
              }
              customer = this.tenantOptions.find(
                (item) => item.id == this.$store.state.customerId);
            } else {
              customer = this.tenantOptions.find(
                (item) => item.name == this.selectedCustomers[element.id]
              );
            }
  
            if (customer) {
              if (customers[customer.id] !== undefined) {
                // A customer entry was already created for this row, append to it.
                //
                const tenantIds = Object.keys(tenantsForThisCustomer)
                tenantIds.forEach(tenantId => {
                  customers[customer.id].tenants[tenantId] = tenantsForThisCustomer[tenantId]
                })
              }
              else {
                // Create a new entry for this row.
                //
                customers[customer.id] = {
                  permissions: customerPermissions,
                  tenants: tenantsForThisCustomer,
                };
              }
            }
            else {
              this.$log.error(`No customer found for item id=${element.id}`)
            }
          }
        });
        this.userInfo.customers = customers;
      },
      async createUser() {
        var okay = true;
        if (!this.validateUserInputField() || !this.isTenantAdded()) {
          this.$bvToast.toast(
            "Required data is missing or invalid.",
            {
              autoHideDelay: 5000,
              solid: true,
              title: "Error",
              variant: "warning",
            }
          );
          okay = false;
        }
        if (okay) {
          try {
            this.isLoading = true;
            this.populateCustomerDataForUser();
            this.userInfo.password = userAdministrationWebServices.generatePassword();
  
            const response = await userAdministrationWebServices.createUser(this.userInfo);
            if (response && response.user_id) {
              await this.saveNewUserSetting(response.user_id)
  
              this.$bvToast.toast(
                "User created successfully and temporary password has been sent to registered email.",
                {
                  autoHideDelay: 5000,
                  solid: true,
                  title: "Create User",
                  variant: "success",
                }
              );
              this.selectedPermissions = { 0: {} };
              this.selectedTenants = {};
              this.userInfo = {
                username: "",
                email: "",
                firstName: "",
                lastName: "",
                mobileNumber: "",
              };
              this.items = [];
              setTimeout(() => {
                this.$router.push("/user_administration");
              }, 500);
            }
            else {
              this.$bvToast.toast("Error occurred creating user account.", {
                autoHideDelay: 5000,
                solid: true,
                title: "Create User",
                variant: "danger",
              });
            }
          }
          catch (error) {
            this.$log.error(`Error returned from web services: ${error.message}`)
            const errMsg = (error?.response?.status == 409)
              ? "Username and/or email address are already associated with another account." 
              : "Error occurred creating user account."
            this.$bvToast.toast(errMsg, {
              autoHideDelay: 5000,
              solid: true,
              title: "Create User",
              variant: "danger",
            });
          }
  
          this.isLoading = false;
        }
      },
      async saveNewUserSetting(userId) {
        for (var index = 0; index < this.items.length; index++) {
          const customerName = this.selectedCustomers[this.items[index].id];
          if (!this.showWorklistSettings(this.items[index].id)) {
            this.$log.debug(`Skipping duplicate settings update for customer=[${customerName}]`)
            continue
          }
  
          const selectedCustomer = this.tenantOptions.find(item => item.name == customerName)
          if (selectedCustomer) {
            const customerId = selectedCustomer.id;
            try {
              await userAdministrationWebServices.updateUserSettings(userId, customerId, this.items[index].worklistSettings);
              this.$log.debug(`Saved user settings user_id=${userId} customer_id=${customerId}.`)
            }
            catch (error) {
              this.$log.error(`Unable to save user settings user_id=${userId} customer_id=${customerId}: ${error.message}`)
            }
  
            try {
              const signature = this.items[index].signature
              if (signature) {
                await userAdministrationWebServices.updateUserSignature(userId, customerId, new Blob([signature.file]), signature.fileName);
                this.$log.debug(`Saved signature user_id=${userId} customer_id=${customerId}.`)
              }
              else {
                this.$log.debug(`No signature to save for user_id=${userId} customer_id=${customerId}.`)
              }
            }
            catch (error) {
              this.$log.error(`Unable to save signature user_id=${userId} customer_id=${customerId}: ${error.message}`)
            }
          }
          else {
            this.$log.error(`Unable to find customer ID for customer name=[${customerName}]`)
          }
        }
      }
    },
  };
  </script>
  
  <style>
  .white-font {
    color: #fff;
  }
  
  .user-administration-dropdown .dropdown-menu {
    max-height: 500px;
    overflow-y: auto;
    width: 100%;
  }
  </style>
  
  <style scoped>
  .no-spinners::-webkit-inner-spin-button,
  .no-spinners::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .no-spinners {
    appearance: textfield;
    -moz-appearance: textfield;
  }
  </style>
  <style scoped>
    
  .dropdown-check-list .anchor {
    position: relative;
    cursor: pointer;
    display: inline-block;
    padding: 5px 50px 5px 10px;
    border: 1px solid #ccc;
  }
  
  .dropdown-check-list .anchor:after {
    position: absolute;
    content: "";
    border-left: 2px solid black;
    border-top: 2px solid black;
    padding: 5px;
    right: 10px;
    top: 20%;
    -moz-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
  
  .dropdown-check-list .anchor:active:after {
    right: 8px;
    top: 21%;
  }
  .dropdown-user-administration-dropdown {
    border-bottom: 2px solid transparent;
    border-top: 2px solid transparent;
  }
  
</style>